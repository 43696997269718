.rootForImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.product {
  /* background-color: #fff; */
  /* border: 1px solid #e7edf1; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0 0px 0px rgb(0 0 0 / 8%), 0 0px 8px rgb(0 0 0 / 12%); */
  cursor: pointer;
  /* border: 1px solid black; */
  border-radius: 15px;
  margin: 0rem 0.75rem;
  min-width: 240px;
  max-width: 240px;
  height: 380px; /* Fixed height to prevent layout shifts */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This ensures content is properly spaced */
  overflow: hidden; /* Ensure nothing sticks out */
}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.productImageWrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
  height: 230px;
  width: 100%;
  & img {
    transition: all 200ms ease;
    /* border-radius: 10px 10px 0px 0px; */
  }
  &:hover {
    & img {
      transform: scale(1.1);
      /* border-radius: 10px 10px 0px 0px; */
    }
  }

  & p:first-of-type {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    margin: 0;
    color: #fff;
    font-size: 0.8rem;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
}

.imagePlaceholder {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 14px;
}

.productBodyWrapper {
  /* padding: 0 1rem; */
  & h3 {
    /* font-size: 0.8rem; */
    /* color: #999; */
    /* font-weight: normal; */
    line-height: 1.4em;
    /* margin: 0; */
    /* text-overflow: ellipsis; */

    /* Needed to make it work */
    /* overflow: hidden; */
    /* white-space: nowrap; */
  }
}

.productPrice {
  & p {
    margin: 0;
    margin-top: 0.5rem;
    color: #111;
    font-size: 0.8rem;
    font-weight: 600;
  }
}
.co2Container {
  display: flex;
  justify-content: space-between;
}

.star {
  fill: orange;
}
.price {
  color: var(--marketplaceColor);
  font-weight: 600;
  margin: 0;
}
.title {
  margin: 0;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
}
.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.container {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}
.genderContainer {
  display: flex;
  margin: 0px 10px;
  margin-top: -0.5rem;
}
.iconContainer {
  display: flex;
  margin: 0px 10px;
  margin-bottom: 0.5rem;
  height: 32px; /* Fixed height for icon container */
  align-items: center;
}
.gender {
  margin: 0;
  text-transform: capitalize;
}
.text {
  margin: 0px 10px;
}
.map {
  border-radius: 10px;
  height: 70px;
  margin: 0px 10px;
  /* background-color: orange; */
  margin-bottom: 0.025rem;
}
.mapContainer {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.mapPlaceholder {
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 12px;
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  fill: var(--marketplaceColor);
  margin: 0.25rem;
  font-size: 1.5rem; /* Slightly smaller icon */
}
.star {
  fill: var(--attentionColor);
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}
.age {
  margin-left: 0.25rem;
  color: var(--attentionColor);
  font-size: 14px;
}
.address {
  font-weight: 600;
  color: #000;
}

.localText {
  font-size: 0.85em;
  font-style: italic;
  color: #555;
  margin-left: 4px;
}

/* Screen reader only class - visually hidden but accessible to screen readers */
.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
