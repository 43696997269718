@import '../../styles/customMediaQueries.css';

.main{
    /* w-[60px] h-[60px]*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}
.spinner{
    width: 60px;
    height: 60px;

}
.content{
    /* leading-snug mt-4 text-gray-700 */
    margin-top: 4rem;
    color: gray;
    font-weight: 700;
}
.error{
    /* leading-none text-base text-red-500 ml-2 */
    font-weight: 500;
    color: red;
    margin-left: 2rem;

}