@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  overflow-x: hidden; /* Prevent horizontal scrolling from floating elements */
}

/* Gradient background that transitions from light purple to white */
.gradientBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(230, 220, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%);
  z-index: -1;
  transition: background 0.5s ease;
}

/* Floating circular elements */
.floatingCircle {
  position: absolute;
  border-radius: 50%;
  background: rgba(230, 220, 255, 0.4);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  z-index: -1;
  animation: float 15s infinite ease-in-out;
}

.floatingCircle:nth-child(1) {
  width: 150px;
  height: 150px;
  top: 15%;
  left: 5%;
  animation-delay: 0s;
  animation-duration: 20s;
}

.floatingCircle:nth-child(2) {
  width: 100px;
  height: 100px;
  top: 40%;
  right: 10%;
  animation-delay: 2s;
  animation-duration: 18s;
}

.floatingCircle:nth-child(3) {
  width: 80px;
  height: 80px;
  bottom: 20%;
  left: 15%;
  animation-delay: 4s;
  animation-duration: 22s;
}

.floatingCircle:nth-child(4) {
  width: 120px;
  height: 120px;
  bottom: 30%;
  right: 5%;
  animation-delay: 6s;
  animation-duration: 25s;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  25% {
    transform: translateY(-15px) translateX(10px) rotate(5deg);
  }
  50% {
    transform: translateY(0) translateX(20px) rotate(10deg);
  }
  75% {
    transform: translateY(15px) translateX(10px) rotate(5deg);
  }
  100% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 100vh;
  max-height: 600px;
  padding: 0;
  margin-top: -72px;
  position: relative;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.yourImageClassName {
  position: absolute;
  top: 80px; /* Adjust this value to change vertical position */
  left: 10px; /* Adjust this value to change horizontal position */
  width: 100px; /* Adjust this value if you want to change the badge's width */
  height: auto; /* Maintains the aspect ratio */
  z-index: 10; /* Ensure the badge is on top of other elements */
}

.sections {
  margin: 0;
  padding-top: 1px;
  position: relative;
}

.section {
  overflow: auto;
  position: relative;
  transition: all 0.5s ease;
}

/* Smooth transitions between sections */
.section {
  position: relative;
  padding: 2rem 0;
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.section:hover {
  transform: translateY(-5px);
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

/* Add a subtle shadow to sections for depth */
.section {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  margin-bottom: 2rem;
}

/* Screen reader only class - visually hidden but accessible to screen readers */
.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  transition: transform 0.3s ease;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFeatured {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;
  transition: transform 0.3s ease;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1400px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1200px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;
  border-radius: 3px;
  transition: width 0.3s ease;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.section:nth-of-type(2n):hover .sectionContent::before {
  width: 150px;
  
  @media (--viewportMedium) {
    width: 220px;
  }
}

.modalStyle {
  flex-basis: min-content;
}

.featuredListings {
  margin: 0;
  position: relative;
  z-index: 1;
  margin-top: -15vh; /* Position 15% onto the purple section */
  padding: 0 1.5rem;
  
  @media (--viewportMedium) {
    margin-top: -15vh;
    padding: 0 5%;
  }
  
  @media (--viewportLarge) {
    margin-top: -15vh;
    padding: 0 5%;
  }
  
  /* Ensure it doesn't cover buttons when zoomed in by adding a min-margin-top */
  @media (max-width: 767px) {
    margin-top: 1rem; /* On mobile, don't overlap - push to white section */
  }
  
  /* Handle zoom scenarios by ensuring a minimum distance from the top */
  @media (max-height: 600px) {
    margin-top: 1rem; /* When height is small (like during zoom), don't overlap */
  }
}

.reviewBox {
  margin: 20px 0;
  padding: 0;
  transition: transform 0.3s ease;

  @media (--viewportMedium) {
    margin: 30px 0;
  }

  @media (--viewportLarge) {
    margin: 40px 0;
  }
}

.reviewBox:hover {
  transform: translateY(-5px);
}

/* Press Section Styles */
.pressSection {
  margin: 20px 0;
  padding: 0 24px;
  transition: transform 0.3s ease;

  @media (--viewportMedium) {
    margin: 30px 0;
    padding: 0 36px;
  }

  @media (--viewportLarge) {
    margin: 40px 0;
    padding: 0 48px;
  }
}

.pressTitle {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--matterColor);
}

.pressQuotesContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.pressQuoteCard {
  flex: 1;
  min-width: 250px;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  text-decoration: none;
  color: inherit;
  
  @media (--viewportMedium) {
    max-width: calc(33.333% - 15px);
  }
}

.pressQuoteCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-color: var(--marketplaceColor);
}

.pressQuoteContent {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
}

.pressIconContainer {
  width: 120px;
  height: 50px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pressIcon {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.pressSourceName {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--marketplaceColor);
}

.pressQuoteText {
  font-size: 14px;
  line-height: 1.4;
  color: var(--matterColor);
  margin: 0;
  flex-grow: 1;
}

.loyaltyTestInfo {
  margin: 40px 0;
  padding: 0;
  background-color: var(--matterColorLight);
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @media (--viewportMedium) {
    margin: 60px 0;
  }

  @media (--viewportLarge) {
    margin: 80px 0;
  }
}

.loyaltyTestInfo:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
}

/* FAQ Section Styles */
.faqSection {
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding: 0 1.5rem;

  @media (--viewportMedium) {
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 0 5%;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    margin: 4rem auto 6rem;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Center sections that need full-width alignment */
.centeredSection {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin-top: 48px; /* Add more space above the section on mobile */
  
  @media (--viewportMedium) {
    margin-top: 64px; /* Slightly increase for medium screens */
  }
  
  @media (--viewportLarge) {
    margin-top: 72px; /* Further increase for large screens */
  }
}

/* Ensure the loyalty test info section takes appropriate width */
.loyaltyTestInfo {
  width: 100%;
  max-width: 1128px;
  margin: 0 auto;
}
