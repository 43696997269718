@import '../../../styles/customMediaQueries.css';

.sectionWrapper {
  position: relative;
  padding-bottom: 3rem;
  z-index: 1;
  padding-top: 1rem;
  
  @media (max-width: 767px) {
    padding-top: 0.5rem;
  }
}

.container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 0.5rem 0 2rem 0;
  z-index: 1;
  scroll-padding: 1rem;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  
  /* Add responsive padding */
  @media (--viewportMedium) {
    padding: 0.5rem 1rem 2rem 1rem;
  }
  
  @media (--viewportLarge) {
    padding: 0.5rem 2rem 2rem 2rem;
  }
}
.container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
/* .container {
  display: grid;
  margin: 0 auto;

  grid-template-columns: repeat(auto-fit, minmax(240px, 0fr));
  gap: 2em;
  @media (max-width: 1170px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 90%;
    padding: 1rem 0 3rem 0;
  }
  @media (max-width: 598px) {
    max-width: 85%;
    padding: 1rem 0 3rem 0;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
} */

.productsWrapper {
  /* background-color: #f6f5f7; */
}

.loadingContainer {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    & svg {
      height: 40px;
    }
  }
}

.error {
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin-top: 1rem;
  color: red;
  font-weight: normal;
  font-size: 1rem;
}

.allColumns {
  /* padding-left: 2.5rem; */
  padding-bottom: 5px;
  color: var(--matterColor);
}
/* ////////test */
.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  /* composes: animation; */
  background-color: var(--blackColor);
  border-radius: 25px;
  min-height: 50px;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
  display: block;
  width: 200px;
  margin: 0 auto 1rem auto;
  
  @media (--viewportMedium) {
    margin: 0 0 1rem 1rem;
  }
  
  &:hover {
    background-color: var(--matterColorDark);
  }
}

/* Skeleton UI for loading state - exactly matching ProductCard dimensions and structure */
.skeletonProduct {
  cursor: pointer;
  border-radius: 15px;
  margin: 0rem 0.75rem;
  min-width: 240px;
  max-width: 240px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px; /* Match the approximate height of the ProductCard */
}

.skeletonImageWrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
  height: 230px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeletonContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px;
}

.skeletonTitle {
  width: 70%;
  height: 18px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeletonGenderContainer {
  display: flex;
  margin: 0px 10px;
  margin-top: -0.5rem;
}

.skeletonGender {
  width: 40%;
  height: 14px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeletonRating {
  width: 20%;
  height: 14px;
  margin-left: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeletonMap {
  border-radius: 10px;
  height: 70px;
  margin: 10px 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeletonText {
  width: 80%;
  height: 14px;
  margin: 10px 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeletonIconContainer {
  display: flex;
  margin: 0px 10px;
  margin-bottom: 0.5rem;
}

.skeletonIcon {
  width: 28px;
  height: 28px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 50%;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 16px;
}

.error {
  color: #e53935;
  font-weight: 500;
}

.retryButton {
  background-color: var(--marketplaceColor);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.retryButton:hover {
  background-color: var(--marketplaceColorDark);
}

/* Screen reader only class - visually hidden but accessible to screen readers */
.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  transition: all 0.2s ease;
  display: none; /* Hidden by default on mobile */
  
  @media (--viewportMedium) {
    display: flex; /* Show on desktop */
  }
  
  &:hover {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}

/* Specific selectors for left and right buttons */
.sectionWrapper > button:first-of-type {
  left: 10px;
}

.sectionWrapper > button:last-of-type {
  right: 10px;
}

.navIcon {
  width: 24px;
  height: 24px;
  color: var(--matterColor);
}
